import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Problem`}</h1>
    <p>{`I get stressed out because I often forget when my next meeting is.
Also sometimes when I'm so focussed on the task a hand that I forget about my next meeting.`}</p>
    <h1>{`Solution`}</h1>
    <p>{`Have a constant reminder of when you next meeting is due.
On mac there is an application `}<a parentName="p" {...{
        "href": "https://apps.apple.com/us/app/next-meeting/id1017470484?mt=12"
      }}>{`next meeting`}</a>{`
which helps with this`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      